import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Masonry from 'react-masonry-component';
import Layout from '../components/layout';

const AllWorksPage = ({ data }) => (
  <Layout>
    <h1 className='AllWorksPage__title block__title block__title--dark'>
      My Works
    </h1>
    <Masonry className='showcase'>
      {data.allDatoCmsWork.edges.map(({ node: work }) => (
        <div key={work.id} className='showcase__item'>
          <figure className='card'>
            <Link to={`/works/${work.slug}`} className='card__image'>
              <Img fluid={work.coverImage.fluid} />
            </Link>
            <figcaption className='card__caption'>
              <h6 className='card__title'>
                <Link to={`/works/${work.slug}`}>{work.title}</Link>
              </h6>
              <div className='card__description'>
                <p>{work.excerpt}</p>
              </div>
            </figcaption>
          </figure>
        </div>
      ))}
    </Masonry>
  </Layout>
);

export default AllWorksPage;

export const query = graphql`
  query WorksQuery {
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
